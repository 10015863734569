import { Translate } from 'next-translate';
import {
  Address,
  Maybe,
  StandingOrder,
  DeliveryContactInfo,
  StandingOrderDeliveryFrequency,
} from '@api';

export const getDeliveryInfoAvailability = (
  upcomingDelivery: StandingOrder['upcomingDelivery'] | StandingOrder['currentDelivery'],
) => {
  const noDateDeliveryData =
    !upcomingDelivery?.dayOfWeek || !upcomingDelivery.timeSlot || !upcomingDelivery.date;

  return {
    noDateDeliveryData,
  };
};

export const getDataAvailability = (upcomingDelivery: StandingOrder['upcomingDelivery']) => {
  const noTotalSectionData = !upcomingDelivery?.displayPrice;
  const noDeliveryAddress = !upcomingDelivery?.address;
  const { noDateDeliveryData } = getDeliveryInfoAvailability(upcomingDelivery);
  const noScheduleDeliveryData = noTotalSectionData && noDateDeliveryData && noDeliveryAddress;

  return {
    noTotalSectionData,
    noDateDeliveryData,
    noDeliveryAddress,
    noScheduleDeliveryData,
  };
};

export const getTextSpaceDivided = (textArr: Array<Maybe<string> | undefined>) => {
  let str = '';
  textArr.forEach((elem) => {
    if (!!elem) {
      str += ` ${elem}`;
    }
  });

  return str.trim();
};

export const getTextComaDivided = (textArr: Array<Maybe<string> | undefined>) => {
  let str = '';
  textArr.forEach((elem, index) => {
    if (!!elem) {
      str += index === 0 ? elem : `, ${elem}`;
    }
  });

  return str;
};

export const getAddressText = (address: Address) => {
  const textArr = [address?.address1, address?.apartment];

  return getTextComaDivided(textArr);
};

export const getCityText = (address: Address) => {
  const textArr = [address?.city, address?.state, address?.zipCode];

  return getTextSpaceDivided(textArr);
};

export const getUpcomingDateText = (
  deliveryInfo: StandingOrder['upcomingDelivery'] | StandingOrder['currentDelivery'],
) => {
  const textArr = [deliveryInfo?.dayOfWeek, deliveryInfo?.date];

  return getTextSpaceDivided(textArr);
};

const getUserInfoText = (contact: DeliveryContactInfo, translate: Translate) => {
  const withExtension = !!contact?.phoneNumber?.[0]?.extension;
  const extension = withExtension
    ? `${translate('ext')}. ${contact?.phoneNumber?.[0]?.extension}`
    : '';
  const fullName = `${contact?.firstName} ${contact?.lastName}`;
  const textArr = [fullName, contact?.phoneNumber?.[0]?.phone, extension];

  return getTextSpaceDivided(textArr);
};

export const getDeliveryFrequency = (deliveryFrequency: StandingOrderDeliveryFrequency) => {
  const textArr = [deliveryFrequency.description, deliveryFrequency.timeSlot];

  return getTextComaDivided(textArr);
};
export const getDeliveryText = (address?: Address) => ({
  deliveryCity: address ? getCityText(address) : '',
  deliveryAddress: address ? getAddressText(address) : '',
});

export const getInstructionText = (translate: Translate, contact?: Maybe<DeliveryContactInfo>) => ({
  userInfo: contact ? getUserInfoText(contact, translate) : '',
  instructions: contact?.instructions || '',
  companyName: contact?.companyName || '',
});

export const getStandingOrderAnchor = (id: string) => `standing_order_${id}`;

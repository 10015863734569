import { GetStandingOrdersQuery, StandingOrderProblemType } from '@api';

export interface AlertInfo {
  title: string;
  orderMinimum: number;
  cutOffDeliveryTime?: string | null;
  cutOffDeliveryDate?: string | null;
  orderId: string;
  isNotActivated?: boolean;
  error?: { code?: StandingOrderProblemType; detailsMessage?: string };
}

export const getStandingAlertsInfo = (
  standingOrders?: GetStandingOrdersQuery,
): AlertInfo[] | undefined => {
  return standingOrders?.standingOrders?.reduce((accumulator: AlertInfo[], standingOrder) => {
    const { title, orderMinimum, upcomingDelivery, id, error, warning } = standingOrder;
    const isNotActivated =
      upcomingDelivery?.isReadyForActivation && upcomingDelivery?.isPendingActivation;
    const commonAlertInfo = {
      title,
      orderMinimum,
      orderId: id,
    };

    if (!error?.code && !warning?.code && !isNotActivated) {
      return accumulator;
    }

    if (error?.code) {
      accumulator.push({
        ...commonAlertInfo,
        error: {
          code: error.code,
          detailsMessage: error.detail,
        },
      });
    } else if (warning?.code) {
      accumulator.push({
        ...commonAlertInfo,
        error: {
          code: warning.code,
          detailsMessage: warning.detail,
        },
      });
    } else {
      accumulator.push({
        ...commonAlertInfo,
        isNotActivated,
        cutOffDeliveryTime: upcomingDelivery?.cutOffDeliveryTime,
        cutOffDeliveryDate: upcomingDelivery?.cutOffDeliveryDate,
      });
    }
    return accumulator;
  }, []);
};

import { ReactNode, useState } from 'react';
import { Swiper } from 'swiper/react';
import { Navigation } from 'swiper';
import { Alert } from '@components/UI';
import breakpoints from '@styles/breakpoints.module.scss';
import styles from './AlertWithCarousel.module.scss';
import { SwiperControls } from './components/SwiperControls/SwiperControls';

interface AlertWithCarouselProps {
  children: ReactNode;
  slidesLimit: number;
}

export const AlertWithCarousel = ({ children, slidesLimit }: AlertWithCarouselProps) => {
  const [activeSlide, setActiveSlide] = useState(1);
  const [isStart, setIsStart] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  return (
    <Alert className={styles.alert} type="error" size="medium" isFullWidth>
      <Swiper
        slidesPerView={1}
        navigation={{ nextEl: `.${styles.rightArrow}`, prevEl: `.${styles.leftArrow}` }}
        modules={[Navigation]}
        onSlideChange={(slide) => {
          setIsEnd(slide.isEnd);
          setIsStart(slide.isBeginning);
          setActiveSlide((slide?.activeIndex ?? 0) + 1);
        }}
        breakpoints={{
          [breakpoints.lg]: {
            allowTouchMove: false,
          },
        }}
        role="alert"
        className={styles.swiper}
      >
        {children}
        {slidesLimit > 1 && (
          <SwiperControls
            isEnd={isEnd}
            isStart={isStart}
            activeSlide={activeSlide}
            slidesLimit={slidesLimit}
          />
        )}
      </Swiper>
    </Alert>
  );
};

import { Typography } from '@mui/material';
import cx from 'classnames';
import { StandingOrderProblemType } from '@api';
import { AlertInfo } from '@hooks/standingOrders/getStandingAlertsInfo';
import { routing } from '@constants/routing';
import { useLocalization } from '@hooks/useLocalization';
import { LinkV2 } from '@components/UI/LinkV2/LinkV2';
import { getStandingOrderAnchor } from '@features/StandingOrders/components/StandingOrderRow/helpers';
import styles from './AlertMessage.module.scss';

interface AlertMessagesProps {
  standingAlert?: AlertInfo;
  isSingleAlert?: boolean;
}

export const AlertMessage = ({ standingAlert, isSingleAlert }: AlertMessagesProps) => {
  const standingOrderId = standingAlert?.orderId || '';
  const orderLink = `${routing.standingOrders}#${getStandingOrderAnchor(standingOrderId)}`;
  const { t } = useLocalization();

  // The order of the first four conditions doesn't matter as they are independent and unrelated to each other.
  // Each condition checks for a specific scenario related to the standingAlert object and displays the appropriate message accordingly.

  if (standingAlert?.error?.code === StandingOrderProblemType.MINORDER) {
    return (
      <Typography className={cx(styles.slide_text, { [styles.full_width]: isSingleAlert })}>
        {t('components:standingAlert.minOrder', {
          title: standingAlert?.title,
          minOrderAmount: standingAlert?.orderMinimum,
        })}
      </Typography>
    );
  }

  if (standingAlert?.isNotActivated) {
    return (
      <Typography className={cx(styles.slide_text, { [styles.full_width]: isSingleAlert })}>
        {t('components:standingAlert.notActivated', {
          title: standingAlert?.title,
          minOrderAmount: standingAlert?.orderMinimum,
          cutOffDeliveryTime: standingAlert?.cutOffDeliveryTime,
          cutOffDeliveryDate: standingAlert?.cutOffDeliveryDate,
        })}{' '}
        <LinkV2 href={orderLink} fontWeight="bold">
          {t('components:standingAlert.ReviewAndPlaceOrderButton')}
        </LinkV2>
      </Typography>
    );
  }

  if (standingAlert?.error?.code === StandingOrderProblemType.TECHNICAL) {
    return (
      <Typography className={cx(styles.slide_text, { [styles.full_width]: isSingleAlert })}>
        {t('components:standingAlert.technical')}
      </Typography>
    );
  }

  if (standingAlert?.error?.code === StandingOrderProblemType.TEMPERORY_ERROR) {
    return (
      <Typography className={cx(styles.slide_text, { [styles.full_width]: isSingleAlert })}>
        {standingAlert?.error?.detailsMessage}
      </Typography>
    );
  }

  return (
    <Typography className={cx(styles.slide_text, { [styles.full_width]: isSingleAlert })}>
      {t('components:standingAlert.common', {
        title: standingAlert?.title,
      })}{' '}
      <LinkV2 fontWeight="bold" href={orderLink}>
        {t('common:buttons.viewDetails')}
      </LinkV2>
    </Typography>
  );
};

import { useSwiper } from 'swiper/react';
import { IconButton, Typography } from '@mui/material';
import { arrowLeft as ArrowLeftIcon, arrowRight as ArrowRightIcon } from '@assets/icons/system';
import { useLocalization } from '@hooks/useLocalization';
import styles from './SwiperControls.module.scss';

interface SwiperControlsProps {
  isStart: boolean;
  isEnd: boolean;
  activeSlide: number;
  slidesLimit: number;
}

export const SwiperControls = ({
  isStart,
  isEnd,
  activeSlide,
  slidesLimit,
}: SwiperControlsProps) => {
  const swiper = useSwiper();
  const { t } = useLocalization();

  return (
    <div className={styles.navigation_wrapper}>
      <IconButton
        aria-label="display previous slide"
        className={styles.arrow_icon}
        disabled={isStart}
        disableTouchRipple
        onClick={() => {
          swiper.slidePrev();
        }}
      >
        <ArrowLeftIcon width={12} height={12} />
      </IconButton>
      <Typography className={styles.slide_counter_text} variant="smallBody" component="span">
        {t('components:alertWithCarousel.navigationCounter', {
          activeSlide,
          slidesLimit,
        })}
      </Typography>
      <IconButton
        aria-label="display next slide"
        className={styles.arrow_icon}
        disabled={isEnd}
        disableTouchRipple
        onClick={() => {
          swiper.slideNext();
        }}
      >
        <ArrowRightIcon width={12} height={12} />
      </IconButton>
    </div>
  );
};

import { SwiperSlide } from 'swiper/react';
import { useStandingOrders } from '@hooks/standingOrders/useStandingOrders';
import { getStandingAlertsInfo } from '@hooks/standingOrders/getStandingAlertsInfo';
import { useMainLayoutContext } from '@context/MainLayoutContext';
import { AlertWithCarousel } from '@components/UI/AlertWithCarousel/AlertWithCarousel';
import { FEATURE } from '@modules/featureCheck/FeatureCheck.enums';
import { useHeaderContext } from '@context/HeaderContext/HeaderContext';
import { AlertMessage } from './components/AlertMessage/AlertMessage';
import styles from './StandingOrderAlert.module.scss';

export const StandingOrderAlert = () => {
  const { getFeature } = useHeaderContext();
  const { data, loading } = useStandingOrders();
  const { isStandingOrderEligible } = useMainLayoutContext();
  const standingAlerts = getStandingAlertsInfo(data);
  const isSingleAlert = standingAlerts?.length === 1;
  const shouldNotRenderAlert =
    loading ||
    !standingAlerts?.length ||
    !isStandingOrderEligible ||
    !getFeature?.(FEATURE.STANDING_ORDERS) ||
    !getFeature?.(FEATURE.COS);

  if (shouldNotRenderAlert) {
    return null;
  }

  return (
    <div className={styles.alert_wrapper}>
      <div className={styles.alert_inner_wrapper}>
        <AlertWithCarousel slidesLimit={standingAlerts.length}>
          {standingAlerts?.map((alert) => {
            return (
              <SwiperSlide key={alert?.orderId}>
                <AlertMessage standingAlert={alert} isSingleAlert={isSingleAlert} />
              </SwiperSlide>
            );
          })}
        </AlertWithCarousel>
      </div>
    </div>
  );
};
